html, body {
    height: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
    min-height: 100%;
}

#print {
    overflow: hidden;
}

@media screen {
    #print {
        display: none;
    }
}

@media print {
    #root {
        visibility: hidden;
    }

    #print {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
    }
}
